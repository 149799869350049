<template>
<div class="card">
  <router-link class="img__cont" :to="{ 'name': link, 'params': {'id': id, ...params} }">
    <div v-if="discount && inStock" class="discount">
      {{ discount }}%
    </div>
    <div v-if="is_new" class="new">
        New
      </div>
    <img :src="image" alt="image card photo">
  </router-link>
  <div class="details">
    <router-link class="title" :to="{ 'name': link, 'params': {'id': id, ...params} }">{{ title }}</router-link>
    <div class="btm">
      <div class="prices">
        <div class="old">
          <template v-if="discount && inStock">
            {{ oldPrice }} {{currency}}
          </template>
        </div>
        <div class="current" v-if="inStock"> {{ price }} {{currency}}</div>
        <div class="current" v-else>{{$t('product.inStock')}}</div>
      </div>
      <a class="add__cart" @click="buyProduct" v-show="buy && inStock">
        <span>{{$t('shop.btnAdd')}}</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 5.25003C2.80109 5.25003 2.61032 5.32905 2.46967 5.4697C2.32902 5.61035 2.25 5.80112 2.25 6.00003C2.25 6.19894 2.32902 6.38971 2.46967 6.53036C2.61032 6.67101 2.80109 6.75003 3 6.75003H4.665L6.633 14.625C6.80025 15.2925 7.398 15.75 8.08575 15.75H17.4382C18.1155 15.75 18.6908 15.3 18.8685 14.6475L20.8125 7.50003H19.242L17.4375 14.25H8.085L6.11775 6.37503C6.03649 6.05195 5.84916 5.76549 5.58576 5.56152C5.32236 5.35754 4.99813 5.24786 4.665 5.25003H3ZM16.5 15.75C15.2662 15.75 14.25 16.7663 14.25 18C14.25 19.2338 15.2662 20.25 16.5 20.25C17.7338 20.25 18.75 19.2338 18.75 18C18.75 16.7663 17.7338 15.75 16.5 15.75ZM9.75 15.75C8.51625 15.75 7.5 16.7663 7.5 18C7.5 19.2338 8.51625 20.25 9.75 20.25C10.9838 20.25 12 19.2338 12 18C12 16.7663 10.9838 15.75 9.75 15.75ZM12 5.25003V7.50003H9.75V9.00003H12V11.25H13.5V9.00003H15.75V7.50003H13.5V5.25003H12ZM9.75 17.25C10.173 17.25 10.5 17.577 10.5 18C10.5 18.423 10.173 18.75 9.75 18.75C9.327 18.75 9 18.423 9 18C9 17.577 9.327 17.25 9.75 17.25ZM16.5 17.25C16.923 17.25 17.25 17.577 17.25 18C17.25 18.423 16.923 18.75 16.5 18.75C16.077 18.75 15.75 18.423 15.75 18C15.75 17.577 16.077 17.25 16.5 17.25Z" fill="white"/>
        </svg>
      </a>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    id: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    discount: {
      type: Number,
      default: 0
    },
    price: {
      type: String,
      default: '0'
    },
    oldPrice: {
      type: String,
      default: '0'
    },
    inStock: {
      type: Boolean,
      default: true
    },
    link: {
      type: String,
      default: "shop-item"
    },
    params: {
      type: Object,
      default: () => ({})
    },
    currency: {
      type: String,
      default: "MDL"
    },
    is_new:{
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: () => {}
    }
  },
  data:()=>({
    buy: true,
  }),
  methods: {
    ...mapActions({ 
      add: 'checkout/add',
      sendAddToCartEvent: 'googleEvents/sendAddToCartEvent'
    }),

    buyProduct(){  
     let userId = this.$route.params.id;
     let firstProductId = this.productsBasket[0]?.userId;


      if(firstProductId == userId || firstProductId == undefined && !this.isLogin){
        this.buy = false;
        this.add({
          id : this.id, 
          'userId': userId, 
          payload: this.product
        });
        this.sendAddToCartEvent({
          item: { title: this.title, price: this.price },
          currency: this.currency,
          price: this.price,
        })
      }
      else {
        this.$emit('anotherSpecialist');
      }
      
      
    }
  },
  computed: {
    ...mapGetters({
      inCart : 'checkout/inCart',
      langStorage : 'languages/getLang',
      isLogin: 'user/isLogin',
      productsBasket : 'checkout/products',
    }),
    shopBtnTitle(){
      return (this.langStorage == 'ro') ? 'Adaugă în coș' : (this.langStorage == 'ru') ? 'Добавить' : (this.langStorage == 'es') ? 'Añadir a la lista' : 'Add to list';
    },
  },
  beforeMount(){
    this.buy = !this.inCart(this.id);
  },

}
</script>

<style lang="scss">

  .add__cart {
    cursor: pointer;
  }

  .shop__catalog .row .card .details .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .shop__catalog .row .card .details .btm .prices .old {
    min-height: 24px;
  }
  .shop__catalog .row .card .details .title {
    min-height: 72px;
  }
  .shop__catalog .current {
    @media(max-width: 1600px) and (min-width: 430px) {
      font-size: 20px !important;
    }
  }
  .shop__catalog .row .card .details .btm .prices .old {
     @media(max-width: 1600px) and (min-width: 430px) {
      font-size: 13px !important;
    }
  }
  .shop__catalog .row .card .details .btm .prices {
     @media(max-width: 1600px) and (min-width: 430px) {
      min-height: 64px;
      justify-content: flex-end;
    }
  }
  .shop__catalog .row .card .img__cont {
    height: 416px;
    @media(max-width: 430px)  {
     height: 390px;
    }
    img {
      object-fit: contain;
    }
  }
  .new{
    position: absolute;
    top: 24px;
    right: 24px;
    background-color: #EB236B;
    border-radius: 12px 64px 64px 64px;
    height: 72px;
    width: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #FFF;
  }
</style>